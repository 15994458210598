// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-us-tsx": () => import("./../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-china-tsx": () => import("./../src/pages/china.tsx" /* webpackChunkName: "component---src-pages-china-tsx" */),
  "component---src-pages-conference-room-tsx": () => import("./../src/pages/conference-room.tsx" /* webpackChunkName: "component---src-pages-conference-room-tsx" */),
  "component---src-pages-expand-tsx": () => import("./../src/pages/expand.tsx" /* webpackChunkName: "component---src-pages-expand-tsx" */),
  "component---src-pages-incorporation-grow-tsx": () => import("./../src/pages/incorporation/grow.tsx" /* webpackChunkName: "component---src-pages-incorporation-grow-tsx" */),
  "component---src-pages-incorporation-seed-tsx": () => import("./../src/pages/incorporation/seed.tsx" /* webpackChunkName: "component---src-pages-incorporation-seed-tsx" */),
  "component---src-pages-incorporation-sprout-tsx": () => import("./../src/pages/incorporation/sprout.tsx" /* webpackChunkName: "component---src-pages-incorporation-sprout-tsx" */),
  "component---src-pages-incorporation-tsx": () => import("./../src/pages/incorporation.tsx" /* webpackChunkName: "component---src-pages-incorporation-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-open-tsx": () => import("./../src/pages/open.tsx" /* webpackChunkName: "component---src-pages-open-tsx" */),
  "component---src-pages-relocate-tsx": () => import("./../src/pages/relocate.tsx" /* webpackChunkName: "component---src-pages-relocate-tsx" */),
  "component---src-pages-search-tsx": () => import("./../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-subscribe-tsx": () => import("./../src/pages/subscribe.tsx" /* webpackChunkName: "component---src-pages-subscribe-tsx" */),
  "component---src-pages-upkeep-tsx": () => import("./../src/pages/upkeep.tsx" /* webpackChunkName: "component---src-pages-upkeep-tsx" */),
  "component---src-pages-visa-tsx": () => import("./../src/pages/visa.tsx" /* webpackChunkName: "component---src-pages-visa-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-post-tsx": () => import("./../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

