// Module
import * as React from "react";
import { Link ,navigate} from "gatsby"

// Style
import "./customButton.scss";

// Components
import Button from "reactstrap/lib/Button";

import { withTranslation, WithTranslation } from 'react-i18next';
import { t } from "i18next";
import i18n from "../../../../i18n";
// Variation of the button
// 1. Trigger <Link>
// 2. Hyperlink to external link
// 3. Trigger a custom function
// 4. A button

function changeLanguage( lng: string ){
    return () => {
        i18n.changeLanguage(lng);
        // console.log(i18n.language)
        // const isBrowser = () => typeof window !== "undefined"
        const currentURL = window.location.href
        if(window !== undefined || window !== null || currentURL !== 'https://dev.centreo.hk/'){
        // if(window !== undefined || window !== null || currentURL !== 'http://localhost:8000/'){
            let checkIsPost = currentURL.slice(currentURL.length-1)
            // let checkIsPostAndContactCase = currentURL.slice(currentURL.length-11)
            // let checkLanguage = currentURL.slice(22,25)
            let checkLanguage = currentURL.slice(23,26)
            // let changeUrl = currentURL.slice(0,22) + 'sc_' + currentURL.slice(22)
            let changeUrl = currentURL.slice(0,23) + 'sc_' + currentURL.slice(23)
            // let check = currentURL.slice(0,22) 
            if (typeof window !== "undefined"  && currentURL !== 'https://dev.centreo.hk/' && checkIsPost == '/' && checkLanguage !== 'sc_'){
            // if (typeof window !== "undefined"  && currentURL !== 'http://localhost:8000/' && checkIsPost == '/' && checkLanguage !== 'sc_'){
                // console.log(currentURL.slice(22))
                // if(typeof window !== "undefined"  && checkLanguage !== 'sc_' &&  checkIsPostAndContactCase !== '#contact-us'){
                    
                window.location.replace(
                        `${changeUrl}`
                    )
                // }
            }
            // console.log('checkIsPostAndContactCase' , checkIsPostAndContactCase)
        }
        // console.log('checkIsPost' , checkIsPost)
        // console.log('checkLanguage' , checkLanguage)
        // console.log(currentURL)
        // console.log('check' ,check)
        // console.log(`Language change to ${ln}`)
    }
}

interface ICustomButtonProps {
    text?: string;
    fnTrigger?: any;
    key?: string;
    id?: string;
    link: string;
    // onClick: any;
    variation: "primitive" | "theme" | "affirmative" | "alertwarning";
}
interface IProps extends WithTranslation {
    prop: any;
}

interface IState {
    state: any;
}
class LanguageButtonSc extends React.PureComponent<
    ICustomButtonProps,IProps , IState
    > {
        public componentDidMount() {
            changeLanguage('sc')
            }
    public button() {
        
        return (
            <Button
                className={"custom-button" + " " + this.props.variation}
                onClick={ changeLanguage( "sc" ) }
                key={this.props.key ? this.props.key : ""}
                id={this.props.id}
            >
                <span className="button-text text-light">
                    {this.props.text ? this.props.text : t(`Sc`)}
                </span>
            </Button>
        );
    }
    public render() {
        let buttonType: "int-link" | "ext-href" | "primitive";
        if (
            this.props.link.match("^http") ||
            this.props.link.match("^mailto")
        ) {
            buttonType = "ext-href";
        } else if (this.props.link.match("^/")) {
            buttonType = "int-link";
        } else {
            buttonType = "primitive";
        }
        return (
            <>
                {/* {checkLanguage !== 'Sc_' ? '' : <Link to={currentURL.slice(0,22) + 'Sc_' + currentURL.slice(23)}></Link>} */}
                {buttonType === "ext-href" && (
                    <a href={this.props.link}>{this.button()}</a>
                )}
                {buttonType === "int-link" && (
                    <Link to={this.props.link}>{this.button()}</Link>
                )}
                {buttonType === "primitive" && this.button()}
            </>
        );
    }
}

export default withTranslation('info')(LanguageButtonSc)