import * as React from "react";
import CustomButton from "src/components/Atoms/CustomButton/customButton";
import { Link } from "gatsby"

// Style
import "./header.scss";

// Sitemap
import { siteMap } from "src/data/sitemap";

// Components
import {
    Collapse,
    NavbarToggler,
    Nav,
    NavItem,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import CustomLink from "src/components/Atoms/CustomLink/customLink";

import logo from '../images/logo.png';
import i18n from "../../../../i18n";


import { withTranslation, WithTranslation } from 'react-i18next';
import i18next, { t } from "i18next";
import ChangeLanguageEn from "src/components/Atoms/CustomButton/changeLanguageEn";
import ChangeLanguageSc from "src/components/Atoms/CustomButton/changeLanguageSc";


const changeLanguage = ( ln: any ) =>{
    return () => {
        i18n.changeLanguage(ln);
        // console.log(`Language change to ${ln}`)
        // console.log(i18next.language)
    }
}

interface IProps extends WithTranslation {
    prop: any;
}

interface IState {
    state: any;
}

class Header extends React.Component<{}, { isTop: boolean; isOpen: boolean },{ props : IProps, state : IState }> {
    constructor(props: {}) {
        super(props);
        this.state = {
            isTop: true,
            isOpen: false
        };

        this.toggle = this.toggle.bind(this);
    }
    public componentDidMount() {
        document.addEventListener("scroll", () => {
            const isTop = window.scrollY < 150;
            if (isTop !== this.state.isTop) {
                this.setState({ isTop });
            }
        });
    }

    public toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    // <nav className={`${this.state.isTop ? "bg-transparent" : "up"} pt-3 pb-3 navbar navbar-light bg-light fixed-top`}/>

    public render() {
        // console.log("-----------------" + siteMap.oftenUsed.map((listItem, index) => {console.log(listItem,index)}))
        // console.log("-----------------" + siteMap.howToStart.map((listItem, index) => {console.log(listItem,index)}))
        return (
            <nav
                className={`${
                    this.state.isTop && !this.state.isOpen ? "bg-transparent" : "bg-light scrolled"
                } fixed-top navbar navbar-expand-lg navbar-light  navagate`}
            >
                <Link className="navbar-brand" to="/">
                    <img src={logo} alt="CentreO" />
                </Link>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar={true}>
                    <Nav className="ml-auto" navbar={true}>
                        <NavItem>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle className="m-1" color="null">
                                    <CustomLink
                                        link=""
                                        txtCol="orange"
                                        text={t(`header.nav.Services`)}
                                        variation="default"
                                    />
                                </DropdownToggle>
                                <DropdownMenu className="nav-menu">
                                    {siteMap.oftenUsed.map(
                                        (listItem, index) => (
                                            
                                            <DropdownItem key={index}>
                                                <CustomLink
                                                    variation="default"
                                                    txtCol="dark"
                                                    link={listItem.path}
                                                    text={t(`header.nav.${listItem.text}`)}
                                                />
                                            </DropdownItem>
                                        )
                                    )}
                                    {siteMap.howToStart.map(
                                        (listItem, index) => (
                                            <DropdownItem key={index}>
                                                <CustomLink
                                                    variation="default"
                                                    txtCol="dark"
                                                    link={listItem.path}
                                                    text={t(`header.nav.${listItem.text}`)}
                                                />
                                            </DropdownItem>
                                        )
                                    )}
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </NavItem>

                        <NavItem>
                            <UncontrolledButtonDropdown>
                                <div className="m-1">
                                    <CustomLink
                                        link="/blog"
                                        txtCol="orange"
                                        text={t(`header.nav.Blogs`)}
                                        variation="default"
                                    />
                                </div>
                            </UncontrolledButtonDropdown>
                        </NavItem>
                        {/* <NavItem>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle className="m-1" color="null">
                                    <CustomLink
                                        link=""
                                        txtCol="orange"
                                        text="Resources"
                                        variation="default"
                                    />
                                </DropdownToggle>
                                <DropdownMenu>
                                    {siteMap.resources.map(
                                        (listItem, index) => (
                                            <DropdownItem key={index}>
                                                <CustomLink
                                                    variation="default"
                                                    txtCol="dark"
                                                    link={listItem.path}
                                                    text={listItem.text}
                                                />
                                            </DropdownItem>
                                        )
                                    )}
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </NavItem> */}
                        <NavItem>
                            <UncontrolledButtonDropdown>
                                <div className="m-1" color="null">
                                    <CustomLink
                                        link="/about-us"
                                        txtCol="orange"
                                        text={t(`header.nav.ABOUT-US`)}
                                        variation="default"
                                    />
                                </div>
                            </UncontrolledButtonDropdown>
                        </NavItem>
                    <NavItem>
                            <UncontrolledButtonDropdown>
                                <div className="m-1" color="null">
                                    <CustomLink
                                        link="#contact-us"
                                        txtCol="orange"
                                        text={t(`header.nav.CONTACT-US`)}
                                        variation="default"
                                    />
                                </div>
                            </UncontrolledButtonDropdown>
                        </NavItem>
                        <NavItem>
                            <div className="m-1" color="null" onClick={ changeLanguage( "en" ) }>
                            <ChangeLanguageEn variation="theme" link={""}></ChangeLanguageEn>
                            </div>
                        </NavItem>
                        <NavItem>
                            <div className="m-1" color="null" onClick={ changeLanguage( "sc" ) }>
                            <ChangeLanguageSc variation="theme" link={""}></ChangeLanguageSc>
                            </div>
                        </NavItem>
                        <NavItem>
                            <CustomButton
                                text={t(`header.nav.BOOK-CONFERENCE-ROOM`)}
                                variation="theme"
                                link="/conference-room"
                            />
                        </NavItem>
                    </Nav>
                </Collapse>
            </nav>
            // </Navbar>
        );
    }
}

export default withTranslation('info')(Header)
